@import "https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400;700&family=Ubuntu:wght@300;400;500;700&family=VT323&display=swap";
*, :before, :after {
  box-sizing: border-box;
  font-weight: unset;
  margin: 0;
  padding: 0;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 #f6f54dcc;
  }

  50% {
    box-shadow: 0 0 0 .5em #f6f54d00;
  }

  100% {
    box-shadow: 0 0 #f6f54d00;
  }
}

section {
  width: 100vw;
  height: 100vh;
  padding: 3em;
  position: relative;
}

section h2 {
  text-shadow: 0 0 1em #f6f54d;
  padding-bottom: .25em;
  font-family: VT323, monospace;
  font-size: 4em;
}

section p {
  margin: .5em 0;
  font-size: 1.75em;
}

section a {
  color: #f6f54d;
  font-weight: 400;
}

section ol {
  margin-top: .75em;
  margin-left: 1em;
  font-size: 1.75em;
}

section ol li {
  margin: .5em 0;
  padding-left: .5em;
}

section .key {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Ubuntu Mono, monospace;
  font-size: 1em;
  display: inline;
}

section .text-to-encrypt {
  margin-bottom: .5em;
  font-size: 1.75em;
  display: block;
}

section .slides-scroller {
  width: calc(100vw - 4em);
  color: #120f18;
  text-align: center;
  background-color: #f6f54d;
  border-radius: 8px;
  padding: .5em 0;
  font-size: 1.5em;
  font-weight: 400;
  transition: opacity .6s;
  position: absolute;
  bottom: 2em;
}

section .slides-scroller:hover {
  animation: 1s pulse;
}

section .deactive {
  opacity: .5;
  pointer-events: none;
}

body {
  color: #f6f54d;
  background-color: #120f18;
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
  font-weight: 300;
  overflow: hidden;
}

strong {
  color: #6ef6ff;
  font-weight: 700;
}

.button {
  cursor: pointer;
  transition: text-shadow .3s;
}

.button:hover {
  text-shadow: 0 0 .75em #6ef6ff;
}

.landing {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.landing h1 {
  text-shadow: 0 0 1em #f6f54d;
  padding-bottom: .5em;
  font-family: VT323, monospace;
  font-size: 8em;
}

.scroller {
  cursor: pointer;
}

.mobile-notification {
  padding-top: 1em;
  display: none;
}

@media only screen and (max-width: 1280px) {
  body {
    font-size: 14px;
    overflow: auto;
  }

  .landing h1 {
    font-size: 6em;
  }

  .mobile-notification {
    display: inline;
  }

  section {
    height: auto;
  }

  section h2 {
    font-size: 2.5em;
  }

  section p, section ol {
    font-size: 1.5em;
  }

  section .slides-scroller {
    display: none;
  }
}

/*# sourceMappingURL=index.dec24038.css.map */
