// colors
// TODO: CHANGE COLOR PALETTE!!!

$light: #f6f54d;
$dark: #120f18;
$highlight: #6ef6ff;

// $light: #e1dfe5;
// $dark: #202020;

// other properties

$font-size: 20px;
$font-size-mobile: 14px;
$initial-padding: 3em;

// pulse animation

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba($light, 0.8);
	}

	50% {
		box-shadow: 0 0 0 calc($initial-padding / 6) rgba($light, 0);
	}

	100% {
		box-shadow: 0 0 0 0 rgba($light, 0);
	}
}
