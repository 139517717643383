@use './utilities/reset.scss';
@use './utilities/variables.scss' as *;

@use './section.scss';

@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400;700&family=Ubuntu:wght@300;400;500;700&family=VT323&display=swap');

body {
	color: $light;
	background-color: $dark;

	font-size: $font-size;
	font-family: 'Ubuntu', sans-serif;
	font-weight: 300;

	overflow: hidden;
}

strong {
	color: $highlight; // ? should it stay as it's now?

	font-weight: 700;
}

.button {
	cursor: pointer; 

	transition: text-shadow 300ms;

	&:hover {
		text-shadow: 0 0 .75em $highlight;
	}
}

.landing {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	h1 {
		padding-bottom: 0.5em;

		text-shadow: 0 0 1em $light;

		font-size: 8em;
		font-family: 'VT323', monospace;
	}
}

.scroller {
	cursor: pointer;
}

.mobile-notification {
	display: none;

	padding-top: 1em;
}

@media only screen and (max-width: 1280px) {
	body {
		font-size: $font-size-mobile;
		overflow: auto;
	}

	.landing h1 {
		font-size: 6em;
	}

	.mobile-notification {
		display: inline;
	}

	section {
		height: auto;

		h2 {
			font-size: 2.5em;
		}

		p,
		ol {
			font-size: 1.5em;
		}

		.slides-scroller {
			display: none;
		}
	}
}
