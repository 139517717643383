*,
*::before,
*::after {
	box-sizing: border-box;

	margin: 0;
	padding: 0;

	font-weight: unset;
}
