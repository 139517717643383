@use './utilities/reset.scss';
@use './utilities/variables.scss' as *;

section {
	width: 100vw;
	height: 100vh;

	position: relative;

	padding: $initial-padding;

	h2 {
		padding-bottom: 0.25em;

		text-shadow: 0 0 1em $light;

		font-size: 4em;
		font-family: 'VT323', monospace;
	}

	p {
		margin: 0.5em 0;

		font-size: 1.75em;
	}

	a {
		color: $light;

		font-weight: 400;
	}

	ol {
		margin-top: 0.75em;
		margin-left: 1em;

		font-size: 1.75em;

		li {
			margin: 0.5em 0;
			padding-left: 0.5em;
		}
	}

	.key {
		display: inline;

		font-size: 1em;
		font-family: 'Ubuntu Mono', monospace;

		white-space: pre-wrap;
		word-wrap: break-word;
	}

	.text-to-encrypt {
		display: block;

		margin-bottom: 0.5em;

		font-size: 1.75em;
	}

	.slides-scroller {
		width: calc(100vw - 4em);

		position: absolute;
		bottom: $initial-padding - 1em;

		padding: 0.5em 0;

		color: $dark;
		background-color: $light;

		border-radius: 8px;

		font-weight: 400;
		font-size: 1.5em;

		text-align: center;

		transition: opacity 600ms;

		&:hover {
			animation: pulse 1000ms;
		}
	}

	.deactive {
		opacity: 0.5;

		pointer-events: none;
	}
}
